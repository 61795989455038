export const environment = {
  production: false,
  useEmulators: false,
  firebase: {
    apiKey: 'AIzaSyAVAMe_DWFc1Wjo4Bp6o6wZejRKcq6g7xQ',
    authDomain: 'dev-almedia-movies.firebaseapp.com',
    databaseURL: 'https://dev-almedia-movies.firebaseio.com',
    projectId: 'dev-almedia-movies',
    storageBucket: 'dev-almedia-movies.appspot.com',
    messagingSenderId: '272876981037',
    appId: '1:272876981037:web:a7f59d1b933777b635a1a4',
    measurementId: 'G-F82VFPNLJ7',
  },
  algolia: {
    appId: 'YZ7V8ALP56',
    apiKey: '10451a418289386c8e54842bb3a80452',
  },
  dearcare: 'https://dearcare-check.almediaweb.jp/home/member/',
  spiralmenu:
    'https://area18.smp.ne.jp/area/p/pbqg6qetis8linjod3/0hNVDh/login.html',
  spiralPassword:
    'https://reg18.smp.ne.jp/regist/is?SMPFORM=pbqg-lgmboa-870d37d6411ae34fd2ee360e76459f11',
};
